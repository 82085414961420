
import { Vue, Options } from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Glider: any;

interface Articles {
  title: string;
  hostName: string;
  articleHostDescription: string;
  image: string;
  link: string;
}

@Options({
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName("__header")[0].addEventListener(
        "mousedown",
        () => {
          var slides = document.getElementsByClassName("__slide");
          for (var i = 0; i < slides.length; i++) {
            document.getElementsByClassName("__slide")[i].classList.add("drag");
            document
              .getElementsByClassName("__slide")
              // eslint-disable-next-line no-unexpected-multiline
              [i].classList.remove("move");
          }
        },
        false
      );
      document.getElementsByClassName("glider")[0].addEventListener(
        "mouseup",
        () => {
          var slides = document.getElementsByClassName("__slide");
          for (var i = 0; i < slides.length; i++) {
            document
              .getElementsByClassName("__slide")
              // eslint-disable-next-line no-unexpected-multiline
              [i].classList.remove("drag");
            document.getElementsByClassName("__slide")[i].classList.add("move");
          }
        },
        false
      );
    }, 500);
    var glider = new Glider(document.querySelector(".glider"), {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      arrows: {
        prev: ".arrow_scroll_backward",
        next: ".arrow_scroll_forward",
      },
    });

    this.sliderAuto(glider, 5000);
  },
})
export default class Header extends Vue {
  endOfArticles = false;
  actualArticle = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideTimeout: any;

  articles: Articles[] = [
    {
      title:
        "Jsme celebrity <b>druhý kategorie</b>, udržujeme si chemii a <b>kalíme</b> 150 nocí v roce.",
      hostName: "PSH",
      articleHostDescription: "Rapová skupina labelu Bigg Boss",
      image: require("@/assets/images/psh.jpg"),
      link: "https://seznam.cz",
    },
    {
      title:
        "Život hudebníka může být <b>plný démonů</b>. Já se z nich <b>vypisuju</b> v textech",
      hostName: "Paulie Garand",
      articleHostDescription: "Rapper a producent",
      image: require("@/assets/images/paulie.jpg"),
      link: "https://janov-sy.cz",
    },
    {
      title:
        "Politiku <b>sleduju</b> a snažím se mít <b>přehled</b>, ale v rapu tuhle ambici nemám.",
      hostName: "Ca$hanova Bulhar",
      articleHostDescription: "Člen kolektivu 2L Boyzz",
      image: require("@/assets/images/bulhar.jpg"),
      link: "https://twentio.cz",
    },
    {
      title:
        "Covid mi víc dal, než vzal. Naučil jsem se <b>být sám se sebou</b>. Se svými myšlenkami.",
      hostName: "Yzomandias",
      articleHostDescription: "Zakladatel Milion+ Entertainment",
      image: require("@/assets/images/yzo.png"),
      link: "https://obvio.cz",
    },
  ];

  articleClick(): void {
    if (this.actualArticle >= this.articles.length) {
      this.endOfArticles = true;
    } else {
      this.endOfArticles = true;
      setTimeout(() => {
        var backward = document.getElementsByClassName(
          "arrow_scroll_backward"
        )[0];
        backward.classList.remove("disabled");
      }, 300);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sliderAuto(slider: any, miliseconds: number): void {
    const slidesCount = slider.track.childElementCount;
    var nextIndex = 1;

    const slide = () => {
      this.slideTimeout = setTimeout(function () {
        if (nextIndex >= slidesCount) {
          nextIndex = 0;
        }
        slider.scrollItem(nextIndex++);
      }, miliseconds);
    };

    slider.ele.addEventListener("glider-animated", () => {
      window.clearInterval(this.slideTimeout);
      slide();
    });

    slide();
  }
}

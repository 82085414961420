
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    title: String,
  },
})
export default class HeaderPage extends Vue {
  title!: string;
}

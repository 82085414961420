<template>
  <nav id="__mainMenu" :class="{ scrolled_active: scrolled }">
    <div class="container">
      <div class="logo">
        <router-link to="/">
          <img src="../assets/logo/rap_white_main.svg" alt="Rap! logo" />
        </router-link>
      </div>
      <ul :class="{ scrolledOpened: scrolled, showLinks: menuShow }">
        <li class="__menuLink">
          <router-link to="/historie">Historie</router-link>
        </li>
        <li class="__menuLink">
          <router-link to="/historie">Mladá krev</router-link>
        </li>
        <li class="__menuLink">
          <router-link to="/historie">Móda</router-link>
        </li>
        <li class="__menuLink">
          <router-link to="/historie">Rozhovory</router-link>
        </li>
        <li class="__menuLink">
          <router-link to="/historie">Klipy</router-link>
        </li>
        <li class="__menuLink">
          <router-link to="/historie">Partneři</router-link>
        </li>
      </ul>
      <div class="__menu">
        <button
          title="MENU"
          class="__menuBtn"
          @click="toggleMenu()"
          v-bind:class="{ close: menuShow }"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    title: String,
  },
  mounted() {
    window.addEventListener("DOMContentLoaded", () => {
      this.resolveMenuScroll();

      const links = document.getElementsByClassName("__menuLink");

      for (let i = 0; i < links.length; i++) {
        this.calcWidth(links[i]);
      }
    });
  },
})
export default class Menu extends Vue {
  scrolled = false;
  menuShow = false;

  toggleMenu(): void {
    this.menuShow = !this.menuShow;
  }

  calcWidth(target: any): void {
    target.children[0].style.width = target.offsetWidth + 10 + "px";
  }

  resolveMenuScroll(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const menuNav = document.getElementById("__mainMenu")!;
    const offsetHeightMenuNav = menuNav.offsetHeight;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageHeader = document.getElementById("___headerPage")!;

    if (window.location.pathname == "/" && offsetHeightMenuNav != null) {
      window.addEventListener("scroll", () => {
        var currentScroll = document.documentElement.scrollTop;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const mainHeader = document.getElementById("___header")!;
        var offsetHeightMainHeader = mainHeader.offsetHeight;

        if (currentScroll > offsetHeightMainHeader - offsetHeightMenuNav) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
      });
    } else {
      window.addEventListener("scroll", () => {
        var offsetHeightPageHeader = pageHeader.offsetHeight;
        var currentScroll = document.documentElement.scrollTop;
        if (
          currentScroll >
          (offsetHeightPageHeader || 300) - offsetHeightMenuNav
        ) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.scrolled_active {
  padding: 1.75rem 0;
  background: #000;
  transition: 0.3s cubic-bezier(0.49, 0.47, 0.14, 0.53);
  @media (max-width: 845px) {
    padding: 1rem 0;
  }
}
nav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 3.5rem 0;
  width: 100%;
  z-index: 100;
  transition: 0.3s cubic-bezier(0.49, 0.47, 0.14, 0.53);

  @media (max-width: 555px) {
    padding: 1.5rem 0;
  }

  .container {
    max-width: calc(100% - (3.5rem * 2));
    @media (max-width: 970px) {
      max-width: unset;
      width: 100%;
      margin: 0;
    }
    @media (max-width: 845px) {
      max-width: calc(100% - (3.5rem * 2));
      margin: 0 auto;
    }
    @media (max-width: 555px) {
      max-width: 95%;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      height: 100%;
      position: relative;
      z-index: 2;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 970px) {
          margin-top: 0.5rem;
        }
        img {
          @media (max-width: 970px) {
            width: 75px;
          }
        }
      }
    }
    ul {
      list-style: none;
      display: flex;
      margin-right: 6rem;
      transition: all 1s ease;

      @media (max-width: 1200px) {
        margin-right: 0;
      }
      @media (max-width: 845px) {
        display: none;
      }
      &.scrolledOpened {
        top: -1rem !important;
        @media (max-width: 555px) {
          top: -1rem !important;
        }
      }
      &.showLinks {
        transition: all 1s ease;

        display: flex;
        background: #000;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: -3.5rem;
        left: -3.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 555px) {
          width: 110vw;
          top: -1.5rem;
          left: -6vw;
        }
        li {
          margin-right: 0 !important;

          &:not(:last-of-type) {
            margin-bottom: 1rem;
          }

          a {
            animation: none;
            transition: all 0.2s ease;

            font-size: 24px;
            text-transform: uppercase;
            font-weight: bold;

            width: unset !important;

            &:hover {
              transition: all 0.2s ease;
              animation: none;
              font-size: 26px;
              padding: 1rem 0;
              text-decoration: underline;
            }
          }
        }
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
          margin-right: 3rem;
        }
        &:hover {
          $steps: 50;
          a {
            animation: cray 6s infinite steps($steps);
          }
        }
        a {
          color: #fff;
          position: relative;
          font-size: 1.25rem;
          text-align: center;

          @media (max-width: 1536px) {
            font-size: 18px;
          }
        }
      }
    }
    .__menu {
      position: relative;
      display: none;

      @media (max-width: 845px) {
        display: flex;
      }
      .__menuBtn {
        height: 50px;
        width: 50px;
        transition: all 0.2s ease;
        z-index: 9999;
        border: none;
        outline: none;
        cursor: pointer;
        position: relative;

        span {
          display: block;
          position: absolute;
          height: 2px;
          width: 28px;
          left: 50%;
          transform: translateX(-50%);
          background: #fff;
          border-radius: 10px;
          transition: all 0.15s ease;

          &:first-child {
            top: 17px;
          }

          &:nth-child(2) {
            top: 25px;
          }

          &:last-child {
            top: 33px;
          }
        }

        &.close {
          span {
            &:first-child {
              top: 15px;
              transform: translate(-14px, 10px) rotate(135deg);
            }
            &:nth-child(2) {
              left: 0px;
              opacity: 0;
            }
            &:last-child {
              top: 15px;
              transform: translate(-14px, 10px) rotate(-135deg);
            }
          }
        }
      }
    }
  }
}
</style>


import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    title: String,
  },
  mounted() {
    window.addEventListener("DOMContentLoaded", () => {
      this.resolveMenuScroll();

      const links = document.getElementsByClassName("__menuLink");

      for (let i = 0; i < links.length; i++) {
        this.calcWidth(links[i]);
      }
    });
  },
})
export default class Menu extends Vue {
  scrolled = false;
  menuShow = false;

  toggleMenu(): void {
    this.menuShow = !this.menuShow;
  }

  calcWidth(target: any): void {
    target.children[0].style.width = target.offsetWidth + 10 + "px";
  }

  resolveMenuScroll(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const menuNav = document.getElementById("__mainMenu")!;
    const offsetHeightMenuNav = menuNav.offsetHeight;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageHeader = document.getElementById("___headerPage")!;

    if (window.location.pathname == "/" && offsetHeightMenuNav != null) {
      window.addEventListener("scroll", () => {
        var currentScroll = document.documentElement.scrollTop;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const mainHeader = document.getElementById("___header")!;
        var offsetHeightMainHeader = mainHeader.offsetHeight;

        if (currentScroll > offsetHeightMainHeader - offsetHeightMenuNav) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
      });
    } else {
      window.addEventListener("scroll", () => {
        var offsetHeightPageHeader = pageHeader.offsetHeight;
        var currentScroll = document.documentElement.scrollTop;
        if (
          currentScroll >
          (offsetHeightPageHeader || 300) - offsetHeightMenuNav
        ) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
      });
    }
  }
}


import { Options, Vue } from "vue-class-component";
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    Footer
  }
})
export default class App extends Vue {}

<template>
  <header class="glider __header" id="___header">
    <a
      class="__slide move"
      :href="article.link"
      v-for="article in articles"
      v-bind:key="article"
    >
      <div class="article">
        <div class="container">
          <div class="article_post">
            <h1 class="article_title" v-html="article.title"></h1>
            <p class="article_who">{{ article.hostName }}</p>
            <span class="article_description">
              {{ article.articleHostDescription }}
            </span>
          </div>
        </div>
        <img class="article_image" :src="article.image" />
      </div>
    </a>
  </header>
  <div class="arrow_box">
    <div class="arrows">
      <a class="arrow arrow_scroll_backward" title="Zpět">
        <img src="../assets/logo/arrow-back.svg" alt="Šipka doprava" />
      </a>
      <a class="arrow arrow_scroll_forward" title="Další">
        <img src="../assets/logo/arrow-right.svg" alt="Šipka doprava" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Glider: any;

interface Articles {
  title: string;
  hostName: string;
  articleHostDescription: string;
  image: string;
  link: string;
}

@Options({
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName("__header")[0].addEventListener(
        "mousedown",
        () => {
          var slides = document.getElementsByClassName("__slide");
          for (var i = 0; i < slides.length; i++) {
            document.getElementsByClassName("__slide")[i].classList.add("drag");
            document
              .getElementsByClassName("__slide")
              // eslint-disable-next-line no-unexpected-multiline
              [i].classList.remove("move");
          }
        },
        false
      );
      document.getElementsByClassName("glider")[0].addEventListener(
        "mouseup",
        () => {
          var slides = document.getElementsByClassName("__slide");
          for (var i = 0; i < slides.length; i++) {
            document
              .getElementsByClassName("__slide")
              // eslint-disable-next-line no-unexpected-multiline
              [i].classList.remove("drag");
            document.getElementsByClassName("__slide")[i].classList.add("move");
          }
        },
        false
      );
    }, 500);
    var glider = new Glider(document.querySelector(".glider"), {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      arrows: {
        prev: ".arrow_scroll_backward",
        next: ".arrow_scroll_forward",
      },
    });

    this.sliderAuto(glider, 5000);
  },
})
export default class Header extends Vue {
  endOfArticles = false;
  actualArticle = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideTimeout: any;

  articles: Articles[] = [
    {
      title:
        "Jsme celebrity <b>druhý kategorie</b>, udržujeme si chemii a <b>kalíme</b> 150 nocí v roce.",
      hostName: "PSH",
      articleHostDescription: "Rapová skupina labelu Bigg Boss",
      image: require("@/assets/images/psh.jpg"),
      link: "https://seznam.cz",
    },
    {
      title:
        "Život hudebníka může být <b>plný démonů</b>. Já se z nich <b>vypisuju</b> v textech",
      hostName: "Paulie Garand",
      articleHostDescription: "Rapper a producent",
      image: require("@/assets/images/paulie.jpg"),
      link: "https://janov-sy.cz",
    },
    {
      title:
        "Politiku <b>sleduju</b> a snažím se mít <b>přehled</b>, ale v rapu tuhle ambici nemám.",
      hostName: "Ca$hanova Bulhar",
      articleHostDescription: "Člen kolektivu 2L Boyzz",
      image: require("@/assets/images/bulhar.jpg"),
      link: "https://twentio.cz",
    },
    {
      title:
        "Covid mi víc dal, než vzal. Naučil jsem se <b>být sám se sebou</b>. Se svými myšlenkami.",
      hostName: "Yzomandias",
      articleHostDescription: "Zakladatel Milion+ Entertainment",
      image: require("@/assets/images/yzo.png"),
      link: "https://obvio.cz",
    },
  ];

  articleClick(): void {
    if (this.actualArticle >= this.articles.length) {
      this.endOfArticles = true;
    } else {
      this.endOfArticles = true;
      setTimeout(() => {
        var backward = document.getElementsByClassName(
          "arrow_scroll_backward"
        )[0];
        backward.classList.remove("disabled");
      }, 300);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sliderAuto(slider: any, miliseconds: number): void {
    const slidesCount = slider.track.childElementCount;
    var nextIndex = 1;

    const slide = () => {
      this.slideTimeout = setTimeout(function () {
        if (nextIndex >= slidesCount) {
          nextIndex = 0;
        }
        slider.scrollItem(nextIndex++);
      }, miliseconds);
    };

    slider.ele.addEventListener("glider-animated", () => {
      window.clearInterval(this.slideTimeout);
      slide();
    });

    slide();
  }
}
</script>

<style scoped lang="scss">
.arrow_box {
  position: absolute;
  bottom: 5rem;
  left: 6rem;

  @media (max-width: 576px) {
    bottom: 3rem;
    left: 2rem;
  }

  .arrows {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    position: relative;

    @media (max-width: 576px) {
      width: 60px;
    }

    .arrow {
      transition: 0.2s cubic-bezier(0.49, 0.47, 0.14, 0.53);
      cursor: pointer;
      img {
        @media (max-width: 576px) {
          width: 24px;
          height: 24px;
        }
      }
    }
    .disabled {
      opacity: 0.34;
    }
  }
}

header {
  background: #212226;
  overflow: hidden;
  height: 100vh;

  .__slide {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

  

    /*
      &.move {
      cursor: url("../assets/logo/click.svg"), auto !important;
    }
    &.drag {
      cursor: url("../assets/logo/click.svg"), auto !important;
    }
    
    &.move {
      cursor: url("../assets/logo/cursor-move.svg"), auto !important;
    }
    &.drag {
      cursor: url("../assets/logo/cursor-drag.svg"), auto !important;
    }*/
    .article {
      position: relative;
      width: 100% !important;
      height: 100vh !important;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../assets/logo/screamer_opacity.svg");
        background-repeat: no-repeat;
        background-position: center;
        margin-left: -10rem;
        z-index: -1;

        @media (max-width: 1224px) {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.7),
            transparent 90%
          );
          margin-left: 0;
          z-index: 0;
        }
      }

      .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        z-index: 1;

        @media (max-width: 1224px) {
          align-items: flex-end;
        }

        @media (min-width: 576px) {
          max-width: 85% !important;
        }

        @media (min-width: 768px) {
          max-width: 85% !important;
        }

        .article_post {
          max-width: 500px;

          @media (max-width: 1224px) {
            margin-bottom: 10rem;
            max-width: 400px;
          }
          .article_title {
            color: #fde8db;
            font-weight: 400;
            font-size: 46px;
            margin: 0 0 1.25rem 0;

            @media (max-width: 1536px) {
              font-size: 36px;
            }
            @media (max-width: 1224px) {
              font-size: 24px;
            }
          }
          .article_who {
            color: #ffffff;
            font-size: 26px;
            margin: 0 0 0.5rem 0;
            @media (max-width: 1536px) {
              font-size: 22px;
            }

            @media (max-width: 1224px) {
              font-size: 18px;
            }
          }
          .article_description {
            color: #77787b;
            font-size: 22px;
            @media (max-width: 1536px) {
              font-size: 18px;
            }
            @media (max-width: 1224px) {
              font-size: 1rem;
              color: #b1b1b2;
            }
          }
        }
      }
      .article_image {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
        width: 60%;
        height: 75%;
        object-fit: cover;
        @media (max-width: 1536px) {
          width: 50%;
          height: 70%;
        }
        @media (max-width: 1224px) {
          opacity: 0.74;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

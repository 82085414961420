
import { Options, Vue } from 'vue-class-component';
import Header from '@/components/Header.vue'; // @ is an alias to /src
import TopFresh from '@/components/TopFresh.vue';
import ActualClips from '@/components/ActualClips.vue';
import Menu from '@/components/Menu.vue';

@Options({
  components: {
    Header,
    TopFresh,
    ActualClips,
    Menu
  },
})
export default class Home extends Vue {}

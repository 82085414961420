
import { Vue } from "vue-class-component";

interface Clips {
  title: string;
  author: string;
  subTitle: string;
  videoLink: string;
}

export default class ActualClips extends Vue {
  slice = 2;

  clipsData: Clips[] = [
    {
      title: "Koky - CRUISIN 2000",
      author: "MOST WANTED",
      subTitle:
        "Ty jsi dron, sleduješ mě, kudy chodím, rapuje Koky v novém klipu na kanále Most Wanted. Boombap mu rozhodně není cizí.",
      videoLink: "https://www.youtube.com/embed/TXceCG4SRXA",
    },
    {
      title: "Yzomandias - Designer Flow",
      author: "MILION PLUS",
      subTitle:
        "Jeď tvrdě, nebo jeď domů, vzkazuje Yzomandias v pokračování klipu na Designer Flow.",
      videoLink: "https://www.youtube.com/embed/lSlbX5Dc6Jw",
    },
  ];
}

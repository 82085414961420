<template>
  <div class="container">
    <section>
      <div class="content">
        <h2 class="year">86’</h2>
        <p class="text">
          Prvním hudebníkem, který v tehdejším Československu použil
          <b>rap</b> jako výrazový prostředek, byl Lesík Hajdovský s kapelou
          Manželé, kteří v roce 1984 vydali album Kamufláž. Na něm asi
          nejznámější skladba Jižák vešla v širší známost v polovině 90. let
          díky remixu skupiny Peneři strýčka Homeboye (PSH), která patří mezi
          průkopníky českého hip-hopu jako takového. Mediálně známější se však v
          počátcích stala skupina Chaozz založená v roce 1995, která se se svým
          trackem Policijééé dostala až na přední místa komerčních hitparád.
        </p>
      </div>
      <div class="images">
        <img src="../assets/history/86.png" alt="" />
      </div>
    </section>
    <section>
      <div class="content">
        <h2 class="year">00’</h2>
        <p class="text">
          Začátkem dekády už bylo českých rapperů a hip-hopových uskupení
          nemálo, což dokumentují dvě rozsáhlé celonárodní kompilace Lyrik
          Derby. První nekomerčně úspěšnou hip-hopovou deskou se v roce 2001
          stal Repertoár od PSH. Plzeňská skupina Divokej Západ vydala v roce
          2002 CD. O rok později vyšly desky od dua Indy & Wich a rappera
          P.H.A.T. z Prahy a CD od skupiny DeFuckTo ze Zlína.
          <br />
          <br />
          Velký význam pro další rozvoj hip-hopu v ČR měl bezpochyby třídenní
          festival Hip Hop Kemp poprvé konaný v roce 2002 v Pardubicích, který
          byl první velkou příležitostí v zemi vidět hip-hopové hudebníky ze
          zahraniční, a který nabídl i prostor pro graffiti, breakdance a
          beatbox. Akce si časem získala evropský věhlas a s německým festivalem
          Splash! dnes patří k největším hip-hopovým akcím v Evropě.
        </p>
      </div>
      <div class="images">
        <img src="../assets/history/00.png" alt="" />
      </div>
    </section>
    <section>
      <div class="content">
        <h2 class="year">04’</h2>
        <p class="text">
          V roce 2004 vychází CD kontroverzních Supercrooo, které hudební
          vydavatelství album nejprve odmítla vydat pro obscénnost. Za za hybnou
          sílu českého hip-hopu lze považovat label P.A.trick Records.
          <br />
          <br />
          V roce 2005 nastupuje na scénu mnoho dalších českých raperů, o kterých
          se dá říct, že nastartovali novou éru tzv. gangsta rapu. Jsou to
          hlavně Hugo Toxxx a Tafrob. Tento druh rapu je však v Česku (vzhledem
          k jeho geografické poloze a tedy takřka absenci elementů typu ghett,
          mafie, kuplířství) poněkud neopodstatněný.
        </p>
      </div>
      <div class="images">
        <img src="../assets/history/04-1.png" alt="" />
        <img src="../assets/history/04-2.png" alt="" />
      </div>
    </section>
    <section>
      <div class="content">
        <h2 class="year">07’</h2>
        <p class="text">
          V roce 2007 přichází na scénu velmi progresivní label Bigg Boss pod
          vedením Vladimira 518. Svou neobyčejnost a jisté oproštění od starých,
          zažitých a neprogresivních tendencí dal najevo svým první releasem.
          Byla jím deska Neurobeat od kontroverzní skupiny umělců, jež již za
          hip hop považovat spíše nelze, a sice Sifonova WWW. Ve stejném roce
          konečně vydává své sólo i LA4, člen skupiny Indy & Wich. Byl založen
          label Azurit Kingdom.
          <br />
          <br />
          V roce 2008 vydává sólové album Jay Diesel (Skoop), Vladimir 518 z PSH
          a Hugo Toxxx ze Supercrooo. Dále vychází kompilace 20Ers dokumentující
          20 let od začátku českého rapu. Obsahuje dvě části: nově nahranou část
          a sbírku legendárních českých songů. Nakonec vyšlo také album od
          Jamese Colea (SuperCroo) a Oriona (PSH) s názvem Orikoule. V roce 2008
          vzniká první dokumentární film mapující hip hop v ČR pod názvem Česká
          RAPublika.
          <br />
          <br />
          Rok 2009 přinesl nové album od Tafroba s názvem Sup (odvozené od jeho
          příjmení) a deska od BPM – Horizonty.
          <br />
          <br />
          Produktivní byl rok 2010, kdy vyšlo mnoho nových počinů a byly
          založeny dva nové labely: Ty nikdy založen 2006 (IdeaFatte, Paulie
          Garand, Rest, apod.) a PVP Label (MAAT, Emeres apod.). Z alb vyšla
          nová deska od Emerese – Mount Emeres, Maat – All-in, LA4 – Gyzmo, PSH
          – Epilog, Rest a DJ Fatte – Premiéra, IdeaFatte – Doma, Paulie Garand
          – Harant, Ryes a Kenny Rough – Páni kluci, James Cole – Halucinace ze
          3. patra a Prago Union – Dezorient Express, za které si dokonce
          odnesli Anděla 2010 za hiphopové album roku. Album také vydal Sergei
          Barracuda – Pouliční ekonomická, ostravský street rap pod labelem
          Azurit Kingdom.
          <br />
          <br />
          Převratný byl hlavně rok 2012, v tomto roce na sebe poukazuje
          seskupení YZO Empire. Logic a Jackpot vydávají společné EP s názvem
          Pod vlivem, které produkoval Cassius Cake.
          <br />
          <br />
          V roce 2013 vydal Ektor své převratné album s DJ Wichem – Tetris.
          Marpo se svým labelem Troublegang vydal historicky jeho nejúspěšnější
          desku RIOT! Díky tomuto albu se vyšvihl na 2. místo nejsledovanějšího
          českého rappera v ČR na Facebooku (přes 100 000 fanoušků). Slovenský
          Kontrafakt vydal své album Navždy, které mělo velký úspěch v České
          republice. V roce 2013 vydává skupina YZO Empire (později zakladatelé
          labelu Milion+ Entertainment) desku YZOTAPE (Logic, Jimmy Dickson),
          která toto seskupení dostala do popředí. Pastor z labelu Azurit
          Kingdom vydava album Adyos.
        </p>
      </div>
      <div class="images">
        <img src="../assets/history/07-1.png" alt="" />
        <img src="../assets/history/07-2.png" alt="" />
        <img src="../assets/history/07-3.png" alt="" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class HistoryContent extends Vue {}
</script>

<style scoped lang="scss">
.container {
  section {
    margin: 4rem 0;
    .year {
      color: #4e4f53;
      font-size: 108px;
      margin: 0;
      line-height: 1;
    }
    .text {
      font-size: 18px;
    }

    &:nth-child(1) {
      display: flex;
      justify-content: space-between;
      .content {
        max-width: 600px;
        .text {
          text-align: justify;
        }
      }
      .images {
        img {
          max-width: 500px;
        }
      }
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      .content {
        max-width: 600px;

        .year {
          text-align: right;
        }
      }
      .images {
        margin-top: 5rem;
        img {
          max-width: 500px;
        }
      }
    }
    &:nth-child(3) {
      .content {
        margin-bottom: 2rem;
      }
      .images {
        display: flex;
        justify-content: space-between;
        img {
          width: calc(50% - 0.5rem);
        }
      }
    }
    &:nth-child(4) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;
      .content {
        max-width: 600px;
        .year {
          text-align: right;
        }
      }
      .images {
        display: flex;
        flex-direction: column;
        img {
          max-width: 500px;
          &:first-child {
            margin-top: 5rem;
          }
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
</style>


import { Vue, Options } from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Glider: any;

enum Types {
  Rozhovor = 1,
  Clanek = 2,
  Video = 3,
  Moda = 4,
  Mlada_krev = 5,
}

interface ArticlesFresh {
  title: string;
  type: Types;
  image: string;
  link: string;
  subTitle: string;
  author: string;
  aboutAuthor: string;
  authorImage: string;
}

@Options({
  created() {
    window.addEventListener("load", function () {
      new Glider(document.querySelector(".freshGlider"), {
        slidesToShow: 6,
        slidesToScroll: 1,
        draggable: true,
      });
    });
  },
  mounted() {
    this.topFreshMain = this.topFreshList[0];
  },
})
export default class TopFresh extends Vue {
  endOfArticles = false;
  actualArticle = 0;

  topFreshMain: ArticlesFresh = {
    title: "",
    type: 1,
    subTitle: "",
    image: "",
    link: "",
    author: "",
    aboutAuthor: "",
    authorImage: "",
  };
  topFreshList: ArticlesFresh[] = [
    {
      title: "Ca$hanova Bulhar by rap o politice poslouchat nechtěl",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Orion: Můj novej projekt je tak divnej",
      type: 1,
      subTitle: "Průkopníci",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "The North Face a Gucci releasují společnou kolekci",
      type: 1,
      subTitle: "Móda",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Jak vypadala cesta k dosud největšímu online koncertu v Čechách?",
      type: 1,
      subTitle: "Fenomén Addict",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Dollar Prync český Pop Smoke?",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Ca$hanova Bulhar by rap o politice poslouchat nechtěl",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Orion: Můj novej projekt je tak divnej",
      type: 1,
      subTitle: "Průkopníci",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "The North Face a Gucci releasují společnou kolekci",
      type: 1,
      subTitle: "Móda",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Jak vypadala cesta k dosud největšímu online koncertu v Čechách?",
      type: 1,
      subTitle: "Fenomén Addict",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Dollar Prync český Pop Smoke?",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
  ];
}

<template>
  <Menu/>
  <HeaderPage title="Jak se český rap <b>historicky</b> vyvíjel"/>
  <HistoryContent/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HeaderPage from '@/components/HeaderPage.vue'; // @ is an alias to /src
import Menu from '@/components/Menu.vue'; // @ is an alias to /src
import HistoryContent from '@/components/HistoryContent.vue'; // @ is an alias to /src


@Options({
  components: {
    HeaderPage,
    Menu,
    HistoryContent
  },
})
export default class Home extends Vue {}
</script>

<template>
  <div class="top_clips">
    <div class="container">
      <h2>Nejaktuálnější klipy</h2>
      <div class="_clips">
        <ul class="__clipsList">
          <li class="__clip" v-for="clips in clipsData" v-bind:key="clips">
            <div class="__video">
              <iframe
                width="100%"
                height="420"
                :src="clips.videoLink"
                :title="clips.title"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="clip_text">
              <div class="heading_text">
                <h4>
                  {{ clips.title }}
                </h4>
                <span>
                  {{ clips.author }}
                </span>
              </div>
              <div class="__subTitle">
                <p>„{{ clips.subTitle }}“</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

interface Clips {
  title: string;
  author: string;
  subTitle: string;
  videoLink: string;
}

export default class ActualClips extends Vue {
  slice = 2;

  clipsData: Clips[] = [
    {
      title: "Koky - CRUISIN 2000",
      author: "MOST WANTED",
      subTitle:
        "Ty jsi dron, sleduješ mě, kudy chodím, rapuje Koky v novém klipu na kanále Most Wanted. Boombap mu rozhodně není cizí.",
      videoLink: "https://www.youtube.com/embed/TXceCG4SRXA",
    },
    {
      title: "Yzomandias - Designer Flow",
      author: "MILION PLUS",
      subTitle:
        "Jeď tvrdě, nebo jeď domů, vzkazuje Yzomandias v pokračování klipu na Designer Flow.",
      videoLink: "https://www.youtube.com/embed/lSlbX5Dc6Jw",
    },
  ];
}
</script>

<style scoped lang="scss">
.top_clips {
  .container {
    @media (min-width: 576px) {
      max-width: 100% !important;
      padding: 0;
    }

    @media (max-width: 576px) {
      padding: 0;
    }

    @media (min-width: 768px) {
      max-width: 85% !important;
    }

    h2 {
      font-size: 46px;
      margin: 4rem 0;
      color: #4e4f53;

      @media (max-width: 1536px) {
        font-size: 42px;
      }

      @media (max-width: 1000px) {
        font-size: 28px;
        margin: 2rem 0;
        padding: 0 1rem;
      }

      @media (max-width: 496px) {
        font-size: 24px;
      }
    }

    ._clips {
      .__clipsList {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-column-gap: 40px;

        @media (max-width: 1000px) {
          display: flex;
          flex-direction: column;
        }

        .__clip {
          grid-column-end: span 4;

          @media (max-width: 1000px) {
            margin-bottom: 2rem;
            .clip_text {
              padding: 0 1rem;
            }
          }
          .__video {
            iframe {
              width: 100%;

              @media (max-width: 576px) {
                height: 300px;
              }
            }
          }
          .clip_text {
            .heading_text {
              margin: 1rem 0;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              @media (max-width: 576px) {
               flex-direction: column;
               justify-content: center;
               align-items: flex-start;
              }

              h4 {
                font-size: 1.25rem;
                color: #1d1e23;
                margin: 0;
                font-weight: 400;

                @media (max-width: 576px) {
                  font-size: 18px;
                }
              }
              span {
                position: relative;
                color: #77787b;
                font-size: 1.125rem;
                margin-left: 1rem;

                @media (max-width: 576px) {
                  font-size: 14px;
                    margin-left: 0;

                  &:after {
                    display: none;
                  }
                }
                &:after {
                  content: "";
                  position: absolute;
                  left: -0.5rem;
                  top: 0;
                  width: 1px;
                  height: calc(50%);
                  transform: translateY(50%);
                  background: #77787b;
                }
              }
            }
            .__subTitle {
              p {
                margin: 0;
                color: #77787b;
                @media(max-width: 576px){
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

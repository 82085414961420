<template>
  <router-view />
  <Footer />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    Footer
  }
})
export default class App extends Vue {}
</script>

<style lang="scss" src="./assets/styles.scss">
</style>

<template>
  <div id="___headerPage" class="header_page">
    <div class="container">
      <h1 class="page_title" v-html="title"></h1>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    title: String,
  },
})
export default class HeaderPage extends Vue {
  title!: string;
}
</script>

<style scoped lang="scss">
.header_page {
  background: #212226;
  height: 50vh;
  position: relative;
  @media (max-width: 1536px) {
    height: 60vh;
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    .page_title {
      margin: 0;
      color: #fde8db;
      font-weight: 400;
      font-size: 46px;
      max-width: 400px;
      z-index: 2;
      position: relative;
      @media (max-width: 1536px) {
        font-size: 36px;
      }

      &:after {
        content: "";
        position: absolute;
        z-index: 0;
        top: -8rem;
        left: 4rem;
        width: 100%;
        height: 50vh;
        background: url("../assets/logo/screamer_opacity.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
      }
    }
  }
}
</style>

<template>
  <div class="top_fresh">
    <div class="container">
      <h2>Nejčersvější články</h2>
      <div class="first_fresh">
        <a href="#" class="fresh_content">
          <h4 class="__subtitle">{{ topFreshMain.subTitle }}</h4>
          <h3 class="__title">{{ topFreshMain.title }}</h3>
          <div class="author">
            <img
              v-on:title="topFreshMain.title"
              class="author_image"
              src="../assets/images/paulie.jpg"
            />
            <div class="text">
              <p class="__author_name">{{ topFreshMain.author }}</p>
              <span class="__about_author">{{ topFreshMain.aboutAuthor }}</span>
            </div>
          </div>
        </a>
      </div>
      <div class="freshGlider __freshList">
        <div
          class="__freshArticle"
          v-for="article in topFreshList.slice(1)"
          v-bind:key="article"
        >
          <a class="__freshLink" href="#">
            <span class="article_type">Rozhovor</span>
            <span class="article_subTitle">{{ article.subTitle }}</span>

            <p class="article_title" v-if="article.title.length > 34">
              {{ article.title.substr(0, 34) + "..." }}
            </p>
            <p class="article_title" v-else>
              {{ article.title }}
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Glider: any;

enum Types {
  Rozhovor = 1,
  Clanek = 2,
  Video = 3,
  Moda = 4,
  Mlada_krev = 5,
}

interface ArticlesFresh {
  title: string;
  type: Types;
  image: string;
  link: string;
  subTitle: string;
  author: string;
  aboutAuthor: string;
  authorImage: string;
}

@Options({
  created() {
    window.addEventListener("load", function () {
      new Glider(document.querySelector(".freshGlider"), {
        slidesToShow: 6,
        slidesToScroll: 1,
        draggable: true,
      });
    });
  },
  mounted() {
    this.topFreshMain = this.topFreshList[0];
  },
})
export default class TopFresh extends Vue {
  endOfArticles = false;
  actualArticle = 0;

  topFreshMain: ArticlesFresh = {
    title: "",
    type: 1,
    subTitle: "",
    image: "",
    link: "",
    author: "",
    aboutAuthor: "",
    authorImage: "",
  };
  topFreshList: ArticlesFresh[] = [
    {
      title: "Ca$hanova Bulhar by rap o politice poslouchat nechtěl",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Orion: Můj novej projekt je tak divnej",
      type: 1,
      subTitle: "Průkopníci",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "The North Face a Gucci releasují společnou kolekci",
      type: 1,
      subTitle: "Móda",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Jak vypadala cesta k dosud největšímu online koncertu v Čechách?",
      type: 1,
      subTitle: "Fenomén Addict",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Dollar Prync český Pop Smoke?",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Ca$hanova Bulhar by rap o politice poslouchat nechtěl",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Orion: Můj novej projekt je tak divnej",
      type: 1,
      subTitle: "Průkopníci",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "The North Face a Gucci releasují společnou kolekci",
      type: 1,
      subTitle: "Móda",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Jak vypadala cesta k dosud největšímu online koncertu v Čechách?",
      type: 1,
      subTitle: "Fenomén Addict",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
    {
      title: "Dollar Prync český Pop Smoke?",
      type: 1,
      subTitle: "Mladá krev",
      image: "",
      link: "",
      author: "Jakub Šponer",
      aboutAuthor: "Redaktor České Televize ART",
      authorImage: "",
    },
  ];
}
</script>

<style scoped lang="scss">
.top_fresh {
  .container {
    @media (min-width: 576px) {
      max-width: 100% !important;
      padding: 0;
    }

    @media (max-width: 576px) {
      padding: 0;
    }

    @media (min-width: 768px) {
      max-width: 85% !important;
    }

    &::after {
      content: "";
      width: 50px;
      height: 100%;
      max-height: 300px;
      background: linear-gradient(
        270deg,
        #212226 0%,
        rgba(196, 196, 196, 0) 100%
      );
      position: absolute;
      opacity: 0.08;
      right: 0;
      bottom: 0;
    }
    h2 {
      font-size: 46px;
      margin: 4rem 0;
      color: #4e4f53;

      @media (max-width: 1536px) {
        font-size: 42px;
      }

      @media (max-width: 1000px) {
        font-size: 28px;
        margin: 2rem 0;
        padding: 0 1rem;
      }
      @media (max-width: 496px) {
        font-size: 24px;
      }
    }
    .first_fresh {
      height: 600px;
      background: url("../assets/images/bulhar.jpg");
      background-size: cover;
      background-position: center;
      position: relative;

      @media (max-width: 496px) {
        height: 400px;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(33, 33, 33, 0) 0%,
          rgba(33, 33, 33, 0.79) 100%
        );
      }
      .fresh_content {
        padding: 2rem;
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        @media (max-width: 576px) {
          padding: 1rem;
        }
        .__subtitle {
          color: #fde8db;
          font-weight: 400;
          font-size: 48px;
          margin: 0;
          @media (max-width: 1536px) {
            font-size: 42px;
          }
          @media (max-width: 1120px) {
            font-size: 32px;
          }

          @media (max-width: 1000px) {
            font-size: 26px;
          }

          @media (max-width: 496px) {
            font-size: 20px;
          }
        }
        .__title {
          color: #fff;
          font-weight: 400;
          font-size: 56px;
          max-width: 60%;
          margin: 0;
          text-transform: uppercase;

          @media (max-width: 1536px) {
            font-size: 52px;
          }
          @media (max-width: 1120px) {
            font-size: 42px;
          }

          @media (max-width: 1000px) {
            font-size: 28px;
          }

          @media (max-width: 496px) {
            font-size: 22px;
            max-width: 80%;
          }
        }
        .author {
          display: flex;
          margin-top: 2rem;
          .author_image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 2rem;
          }
          .text {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            .__author_name {
              color: #fff;
              margin: 0;
            }
            .__about_author {
              margin: 0;
              color: #ffffffa3;
            }
          }
        }
      }
    }
    .__freshList {
      list-style: none;
      display: flex;
      position: relative;
      box-shadow: 0px 0px 40px 9px rgba(0, 0, 0, 0.04);

      .__freshArticle {
        width: calc(100% / 5);
        &:hover {
          background: rgba($color: #77787b, $alpha: 0.04);
        }
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 2px solid rgba(0, 0, 0, 0.12);
        &:first-child {
          border-left: 2px solid rgba(0, 0, 0, 0.12);
          a {
            padding: 1rem 0.5rem 1rem 1rem;
          }
        }
        &:last-child {
          border-right: 2px solid rgba(0, 0, 0, 0.12);
          a {
            padding: 1rem 1rem 1rem 0.5rem;
          }
        }
        .__freshLink {
          padding: 1rem 0.5rem;

          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .article_type {
            @media (max-width: 576px) {
              font-size: 1rem;
            }
          }
          .article_subTitle {
            margin: 8px 0;
            font-size: 1.25rem;

            @media (max-width: 576px) {
              font-size: 1rem;
            }
          }
          .article_title {
            color: #77787b;

            @media (max-width: 576px) {
              font-size: 1rem;
              margin: 0;
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}
</style>

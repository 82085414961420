<template>
  <footer>
    <div class="container">
      <div class="about">
        <img
          class="__footerLogo"
          src="../assets/logo/footer/rap_black_main.svg"
          alt="Rap! logo"
        />
        <div class="mac_miller">
          <p class="site_motto"><b>#</b>generace<b>rap</b></p>
          <img
            class="mac_image"
            src="../assets/images/static/mac_miller.jpg"
            alt="Mac Miller"
          />
          <p class="mac_miller_text">
            Tento web byl vytvořen díky skladbám Maca Millera.
          </p>
        </div>
      </div>
    </div>
    <div class="links">
      <ul class="og_links_list">
        <li class="links_list">
          <h3 class="links_group_title">Hlavní</h3>
          <ul class="links_list_parent">
            <li class="links_child">
              <router-link to=""><span>Historie</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>Mladá krev</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>Móda</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>Rozhovory</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>Klipy</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>Partneři</span></router-link>
            </li>
          </ul>
        </li>
        <li class="links_list">
          <h3 class="links_group_title">Média</h3>
          <ul class="links_list_parent">
            <li class="links_child">
              <router-link to=""><span>Psali o nás</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>Logo manuál</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>O nás</span></router-link>
            </li>
            <li class="links_child">
              <router-link to=""><span>Kontakt</span></router-link>
            </li>
          </ul>
        </li>
        <li class="links_list">
          <h3 class="links_group_title">Sociální sítě</h3>
          <ul class="links_list_parent">
            <li class="links_child">
              <router-link to="">
                <img
                  src="../assets/logo/footer/facebook.svg"
                  alt="Facebook logo"
                />
                <span>Facebook</span></router-link
              >
            </li>
            <li class="links_child">
              <router-link to="">
                <img
                  src="../assets/logo/footer/instagram.svg"
                  alt="Instagram logo"
                />
                <span>Instagram</span></router-link
              >
            </li>
            <li class="links_child">
              <router-link to="">
                <img
                  src="../assets/logo/footer/youtube.svg"
                  alt="YouTube logo"
                />
                <span>YouTube</span></router-link
              >
            </li>
            <li class="links_child">
              <router-link to="">
                <img
                  src="../assets/logo/footer/twitter.svg"
                  alt="Twitter logo"
                />
                <span>Twitter</span></router-link
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </footer>
  <div class="copy container">
    <p>vytvořilo</p>
    <a href="https://twentio.cz" target="_blank"> twentio </a>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Footer extends Vue {}
</script>

<style scoped lang="scss">
footer {
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 965px) {
    flex-direction: column-reverse;
  }
  .container {
    @media (min-width: 965px) {
      max-width: calc(50% - (3.5rem * 2));
    }
    @media (max-width: 965px) {
      max-width: unset;
    }
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    .about {
      display: flex;
      position: relative;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 555px) {
        flex-wrap: wrap;
      }
      .__footerLogo {
        @media (max-width: 970px) {
          width: 75px;
        }
      }
      .mac_miller {
        display: flex;
        margin-left: 9rem;
        position: relative;
        width: 100%;
        flex-direction: column;
        @media (max-width: 576px) {
          margin: 1rem 0 0 0;
        }
        .site_motto {
          font-size: 1.25rem;
          margin: 0.25rem 0 1.5rem 0;
          @media (max-width: 576px) {
            margin: 0 0 1rem 0;
          }
        }
        .mac_image {
          width: 100%;
          @media (max-width: 576px) {
            width: 60%;
          }
        }
        .mac_miller_text {
          color: #77787b;
          font-size: 1.125rem;
          @media (max-width: 576px) {
            font-size: 14px;
            max-width: 80%;
          }
        }
      }
    }
  }
  .links {
    @media (min-width: 965px) {
      width: 50%;
    }

    .og_links_list {
      list-style: none;
      display: flex;
      justify-content: center;

      @media (max-width: 965px) {
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2rem;
      }
      .links_list {
        margin: 0 2rem;

        @media (max-width: 965px) {
          margin: 0;
          margin-bottom: 1rem;
        }
        .links_group_title {
          font-size: 1.25rem;
          margin: 0;
        }
        .links_list_parent {
          list-style: none;

          .links_child {
            a {
              font-size: 1rem;
              color: #77787b;
              display: flex;
              align-items: center;
              img {
                margin: 0 8px 0 0;
                width: 16px;
                height: 16px;
                object-fit: contain;
              }
            }
            &:hover a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
.copy {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 576px) {
    padding: 1rem;
  }
  a {
    margin-left: 0.5rem;
    text-decoration: none;
    color: #212226;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 3px;
    font-family: myriad-pro, sans-serif;
    font-weight: 900;
    font-style: normal;
  }
}
</style>
